@import url(https://fonts.googleapis.com/css?family=PT+Sans+Narrow);


.sectionClass {
  padding: 20px 0px 50px 0px;
  position: relative;
  display: block;
}

.fullWidth {
  width: 100% !important;
  display: table;
  float: none;
  padding: 0;
  min-height: 1px;
  height: 100%;
  position: relative;
}


.sectiontitle {
  background-position: center;
  margin: 30px 0 0px;
  text-align: center;
  min-height: 20px;
}

.sectiontitle h2 {
  font-size: 30px;
  color: #222;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-left: 10px;
}


.headerLine {
  width: 160px;
  height: 2px;
  display: inline-block;
  background: #101F2E;
}


.projectFactsWrap{
    display: flex;
  margin-top: 30px;
  flex-direction: row;
  flex-wrap: wrap;
}


#projectFacts .fullWidth{
  padding: 0;
}

.projectFactsWrap .item{
  width: 25%;
  height: 100%;
  padding: 50px 0px;
  text-align: center;
}

.projectFactsWrap .item:nth-child(1){
  /*background: rgb(16, 31, 46);*/
  /* background: #FF8C00; */
  background: #0086da;
}

.projectFactsWrap .item:nth-child(2){
  /*background: rgb(18, 34, 51);*/
  /* background: #FF4500; */
  background: #3149a6;
}

.projectFactsWrap .item:nth-child(3){
  /*background: rgb(21, 38, 56);*/
  /* background: #FFA500; */
  background: #0aa0fd;
}

.projectFactsWrap .item:nth-child(4){
  /*background: rgb(23, 44, 66);*/
  /* background: #F26422; */
  background: #002dda;
}

.projectFactsWrap .item p.number{
  font-size: 40px;
  padding: 0;
  font-weight: bold;
}

.projectFactsWrap .item p{
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  margin: 0;
  padding: 10px;
  font-family: 'Open Sans';
}


.projectFactsWrap .item span{
  width: 60px;
  background: rgba(255, 255, 255, 0.8);
  height: 2px;
  display: block;
  margin: 0 auto;
}


.projectFactsWrap .item i{
  vertical-align: middle;
  font-size: 50px;
  color: rgba(255, 255, 255, 0.8);
}


.projectFactsWrap .item:hover i, .projectFactsWrap .item:hover p{
  color: white;
}

.projectFactsWrap .item:hover span{
  background: white;
}

@media (max-width: 786px){
  .projectFactsWrap .item {
     flex: 0 0 50%;
  }
}

/* AUTHOR LINK */



.authorWindow{
  width: 600px;
  background: #75439a;
  padding: 22px 20px 22px 20px;
  border-radius: 5px;
  overflow: hidden;
}

.authorWindowWrapper{
  display: none;
  left: 110px;
  top: 0;
  padding-left: 25px;
  position: absolute;
}





.trans{
  opacity: 1;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

@media screen and (max-width: 768px) {
    .authorWindow{
         width: 210px;
    }

    .authorWindowWrapper{
             bottom: -170px;
  margin-bottom: 20px;
    }

  

}

.fix-invest-now-btn {
    position: fixed;
    bottom: 80px;
    /*right: 0px;
    right: 0px;*/
  z-index: 22;
  /*bottom: -25.5px;*/
  /*left: 15%;*/
  left: 80%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(0%); 
  width: 20%;  /*Add for center rectangle div btn */
}


  .fix-invest-now-btn a {
    height: 51px;
    /*width: 130px;*/
    width: 49%;
    /*background-image: -webkit-linear-gradient(120deg, #505add 0%, #df42b1 100%);
    background-image: -ms-linear-gradient(120deg, #505add 0%, #df42b1 100%);*/
    background-image: -webkit-linear-gradient(120deg, #f26422 0%, #FFC403 100%);
    background-image: -ms-linear-gradient(120deg, #f26422 0%, #FFC403 100%);
    text-align: center;
    border: 2px solid #ffffff;
    /*border-radius: 50%;*/
    border-radius: 0%;
    line-height: 47px;
    color: #ffffff;
    font-size: 10px;
    display: inline-block; }

    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .fix-invest-now-btn {
          position: fixed;
          bottom: 20px;
          /*right: 0px;
          right: 0px;*/
        z-index: 22;
        /*bottom: -25.5px;*/
        /*left: 15%;*/
        left: 60%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(0%); 
        width: 55%;  /*Add for center rectangle div btn */
      } }
    @media only screen and (max-width: 767px) {
      .fix-invest-now-btn {
          position: fixed;
          bottom: 20px;
          /*right: 0px;
          right: 0px;*/
        z-index: 22;
        /*bottom: -25.5px;*/
        /*left: 15%;*/
        left: 55%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(0%); 
        width: 55%;  /*Add for center rectangle div btn */
      }
    }
    .col-12 .col-md-3 .col-xl-3 a::before {
      color: #000000 !important; 
    }

    
.login{
  width:500px;
  /* margin:50px auto; */
  background:#f5f9fa;
  padding:50px;
  border-radius:8px;
  text-align:center;
}
.visuallyhidden{
  position:absolute;
  overflow:hidden;
  clip:rect(0 0 00);
  height:1px; width: 1px;
  margin:-1px; 
  padding:0;
  border:0;
}
input[type=text],input[type=email]{
  width:100%;
  border:1px solid #ccc;
  padding:8px;
  /* text-align:center; */
}
input[type=text]:focus,input[type=email]:focus{
  border:1px solid #888;
  outline:none;
}

input {
  margin:6px 0;
}
input[type=text]:valid + input[type=submit],
input[type=email]:valid + input[type=password] {
  pointer-events: auto;
  -webkit-filter: none;
  transform: scale(1);
  opacity: 1;
}


@media only screen and (max-width: 767px) {
  .login{
    width:300px;
    margin:50px auto;
    background:#f5f9fa;
    padding:50px;
    /* margin: auto; */
    border-radius:8px;
    text-align:center;
  }
  .custom_brand{
    width:300px;
    /* margin:50px auto; */
    margin:20px auto;
    /* padding:50px; */
    padding:3px;
  }
}

.welcome-area {
  position: relative;
  z-index: 1; }
  .welcome-area .icon-scroll {
    width: 26px;
    height: 39px;
    position: absolute;
    z-index: 999999;
    left: 50%;
    bottom: 40px;
    margin-left: -13px;
    -webkit-box-shadow: inset 0 0 0 2px #ffffff;
    box-shadow: inset 0 0 0 2px #ffffff;
    border-radius: 25px;
    opacity: 0.75;
    cursor: pointer; }
    .welcome-area .icon-scroll::before {
      position: absolute;
      z-index: 999999;
      content: '';
      width: 6px;
      height: 6px;
      background: #ffffff;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      top: 8px;
      border-radius: 4px;
      -webkit-animation: iconScroll linear 1200ms infinite;
      animation: iconScroll linear 1200ms infinite; }

  
      
.single-welcome-slide {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 980px;
  background-color: #fd7e14; /*#df42b1;*/
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-welcome-slide {
    /* height: 800px; */
    height: 1150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-welcome-slide {
    /* height: 800px; */
    height: 1150px;
  }
}
@media only screen and (max-width: 767px) {
  .single-welcome-slide {
    /* height: 700px; */
    height: 1057px;
  }
}
.single-welcome-slide .welcome-text {
  position: relative;
  z-index: 1;
}
.single-welcome-slide .welcome-text h2 {
  position: relative;
  z-index: 1;
  font-weight: 700;
  font-size: 90px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 10px;
  color: #ffffff;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-welcome-slide .welcome-text h2 {
    font-size: 60px;
  }
  .custom_brand h3,span {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .single-welcome-slide .welcome-text h2 {
    font-size: 30px;
  }
  .custom_brand h3,span {
    text-align: center;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .single-welcome-slide .welcome-text h2 {
    font-size: 42px;
  }
  .custom_brand h3,span {
    text-align: center;
  }
}
.single-welcome-slide .welcome-text h2::after {
  position: absolute;
  content: "";
  height: 23px;
  width: 400px;
  top: 67px;
  right: 5px;
  z-index: -1;
  /*background-image: -webkit-linear-gradient(120deg, #505add 0%, #df42b1 100%);
        background-image: -ms-linear-gradient(120deg, #505add 0%, #df42b1 100%); */
  /*background-image: -webkit-linear-gradient(120deg, #f26422 0%, #f26ff2 100%);
        background-image: -ms-linear-gradient(120deg, #f26422 0%, #f26ff2 100%); */
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-welcome-slide .welcome-text h2::after {
    top: 40px;
    height: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .single-welcome-slide .welcome-text h2::after {
    top: 45px;
    height: 20px;
    right: 0;
    width: 240px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .single-welcome-slide .welcome-text h2::after {
    top: 20px;
  }
}
.single-welcome-slide .welcome-text h6 {
  color: #ffffff;
  margin-bottom: 50px;
  text-transform: uppercase;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px 20px;
  font-size: 18px;
}
@media only screen and (max-width: 767px) {
  .single-welcome-slide .welcome-text h6 {
    font-size: 14px;
  }
}
.single-welcome-slide .welcome-text .hero-mail-contact {
  color: #ffffff;
  font-style: italic;
  text-decoration: underline;
}
.single-welcome-slide .welcome-text .hero-mail-contact:hover,
.single-welcome-slide .welcome-text .hero-mail-contact:focus {
  color: #fd7e14; /*#df42b1;*/
}
.single-welcome-slide .welcome-text-two {
  position: relative;
  z-index: 1;
}
.single-welcome-slide .welcome-text-two h5 {
  font-size: 40px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
}
@media only screen and (max-width: 767px) {
  .single-welcome-slide .welcome-text-two h5 {
    font-size: 20px;
  }
}
.single-welcome-slide .welcome-text-two h2 {
  font-size: 90px;
  color: #ffffff;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-weight: 700;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-welcome-slide .welcome-text-two h2 {
    font-size: 62px;
  }
}
@media only screen and (max-width: 767px) {
  .single-welcome-slide .welcome-text-two h2 {
    font-size: 30px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .single-welcome-slide .welcome-text-two h2 {
    font-size: 42px;
  }
}
.single-welcome-slide .welcome-text-two .event-meta {
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
}
.single-welcome-slide .welcome-text-two .event-meta a {
  color: #ffffff;
  margin-right: 40px;
}
@media only screen and (max-width: 767px) {
  .single-welcome-slide .welcome-text-two .event-meta a {
    margin-right: 15px;
    font-size: 12px;
  }
}
.single-welcome-slide .welcome-text-two .event-meta a i {
  margin-right: 5px;
}

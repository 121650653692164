body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
* {
  padding: 0;
  margin: 0;
  /* margin: -4px; */
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
 }
 
 @media only screen and (max-width: 767px) {
  .custom_brand, span {
      text-align: center;
  }
  .navbar-brand {
    margin-right: 0rem;
  }
}

 :root {
  --mainColor: #29335c;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: #eee;
 }
.about-us-countdown-area {
    background-color: #ffffff;
}
.about-us-countdown-area .about-content-text h3 {
    color: #111343;
}
.about-us-countdown-area .about-content-text p {
    color: #111343;
}
.mt-5, .my-5 {
    margin-top: 0rem!important;
}
.section-padding-100-60 {
    padding-top: 40px;
}